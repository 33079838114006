import { HTTPURL } from '../constants/Matcher';

export async function Summative_assessment_List(type) {
    try {
        const response = await fetch(HTTPURL + `api/student/student-all-assessment?type_id=${type}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('Token')}`
            }
        });
        const responseData = await response.json();
        if (responseData.status) {
            // responseData?.message;
        }
        // else {
        //    responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function Assesment_question_Detail(id) {
    try {
        const response = await fetch(HTTPURL + `api/student/single-class-question-answer-user?id=${id}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('Token')}`
            }
        });
        const responseData = await response.json();
        if (responseData.status) {
            // responseData?.message;
        }
        // else {
        //    responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
